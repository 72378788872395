import { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Grid, CircularProgress, } from '@mui/material';
import DashboardLayout from './DashboardLayout.js';
const Contacts = lazy(() => import('./contacts/Contacts'));
const OrgDashboard = lazy(() => import('./dashboard/OrgDashboard'));
const EmailTemplateEditor = lazy(() => import('./templates/Editor'));
const ContactDetails = lazy(() => import('./contacts/ContactDetails'));
const Sections = lazy(() => import('./Sections.js'));
const Settings = lazy(() => import('./Settings.js'));
const TeamMembersList = lazy(() => import('./teams/index.js'));
const Organisations = lazy(() => import('./organisations/index.js'));
const PendingOrgJoinRequests = lazy(() => import('./organisations/PendingOrgJoinRequests.js'));
const Segments = lazy(() => import('./segments/index'));
const Survey = lazy(() => import('./survey/SurveyListing.js'))
const NewSurvey = lazy(() => import('./survey/Create.js'))
const AddOrEditSurvey = lazy(() => import('./survey/index.js'))
const Tasks = lazy(() => import('./tasks/TaskListing.js'));
const Task = lazy(() => import('./tasks/index.js'));
const MyTasks = lazy(() => import('./mytasks/MyTasksListing.js'));
const MyTasksDetailDashboard = lazy(() => import('./mytasks/index.js'));
const MyPeople = lazy(() => import('./mypeople/mypeople.js'));
const TemplatesList = lazy(() => import('./templates/TemplateCreator.js'));
const ContactForm = lazy(() => import('./contacts/AddContact.js'));
const ExportContacts = lazy(() => import('./contacts/ExportContacts.js'));
const ViewSurveyResponses = lazy(() => import('./survey/ViewSurveyResponses.js'));
const WalkListDetail = lazy(() => import('./mytasks/WalkListDetail.js'));
const TeamListing = lazy(() => import('./teams/TeamListing'));
const SavedListDetailPage = lazy(() => import('./segments/SavedListDetailPage'));
const SavedListWrap = lazy(() => import('./segments/SavedListWrap'));
const Notifications = lazy(() => import('./notifications/index.js'));
const SheetImportHistory = lazy(() => import('./contacts/SheetImportHistory'));

const DashBoard = () => <div>Home</div>; // Redirect to /login route


const AuthRoutes = () => {
    return (
        <DashboardLayout>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} style={{ marginTops: '10vh' }}>
                    <Suspense
                        fallback={
                            <Grid item xs={12} md={12} lg={12} sx={{ justifyContent: 'center', display: 'flex', marginTop: '50px' }}>
                                <CircularProgress />
                            </Grid>
                        }
                    >
                        <Routes>
                            <Route path="/org/:orgId/dashboard" element={<OrgDashboard />} />
                            <Route path="/org/:orgId/contacts" element={<Contacts />} />
                            <Route path="/org/:orgId/contacts/:contactId" element={<ContactDetails />} />
                            <Route path="/org/:orgId/contacts/addcontact" element={<ContactForm />} />
                            <Route path="/org/:orgId/contacts/export" element={<ExportContacts />} />
                            <Route path="/org/:orgId/my-people" element={<MyPeople />} />
                            <Route path="/org/:orgId/notifications" element={<Notifications />} />
                            <Route path="/org/:orgId/members" element={<TeamMembersList />} />
                            <Route path="/org/:orgId/my-tasks" element={<MyTasks />} />
                            <Route path="/org/:orgId/my-tasks/:taskId" element={<MyTasksDetailDashboard />} />
                            <Route path="/org/:orgId/admin-tasks" element={<Tasks />} />
                            <Route path="/org/:orgId/my-tasks/:taskId/survey/:surveyId/responses/:contactId" element={<ViewSurveyResponses />} />
                            <Route path="/org/:orgId/my-tasks/:taskId/:surveyId/:contactId" element={<WalkListDetail />} />
                            <Route path="/org/:orgId/admin-tasks/create" element={<Task />} />
                            <Route path="/org/:orgId/admin-tasks/:taskId" element={<Task />} />
                            <Route path="/org/:orgId/surveys" element={<Survey />} />
                            <Route path="/org/:orgId/surveys/:surveyId" element={<AddOrEditSurvey />} />
                            <Route path="/org/:orgId/surveys/create" element={<NewSurvey />} />
                            <Route path="/org" element={<Organisations />} />
                            <Route path="/dashboard" element={<DashBoard />} />
                            <Route path="/org/:orgId/pending-requests" element={<PendingOrgJoinRequests />} />
                            <Route path="/org/:orgId/settings" element={<Settings />} />
                            <Route path="/org/:orgId/sections" element={<Sections />} />
                            <Route path="/org/:orgId/segments" element={<Segments />} />
                            <Route path="/org/:orgId/teams" element={<TeamListing />} />
                            <Route path="/org/:orgId/email-templates" element={<TemplatesList />} />
                            <Route path="/org/:orgId/create-template" element={<EmailTemplateEditor />} />
                            <Route path="/org/:orgId/email-templates/preview-template/:templateID" element={<EmailTemplateEditor />} />
                            <Route path="/org/:orgId/segments/saved-list/:listId" element={<SavedListDetailPage />} />
                            <Route path="/org/:orgId/assigned-lists/:listId" element={<SavedListDetailPage />} />
                            <Route path="/org/:orgId/assigned-lists" element={<SavedListWrap />} />
                            <Route path="/org/:orgId/import-history" element={<SheetImportHistory />} />
                        </Routes>
                    </Suspense>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};
export default AuthRoutes;