import {Box} from "@mui/material";
export const formControlStyle = {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
  };

  export const selectStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '150px',
    backgroundColor: "#FFFFFF"
  };

  export const formatSupportLevel = (supportLevel) => {
      if (!supportLevel) return '-';
      return supportLevel
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  };

  export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
      </div>
    );
  };

  export const formatContactName = (contact) => {
    const { first_name, middle_name, last_name } = contact || {};
    return <span style={{fontWeight:"500"}}>{[first_name, middle_name, last_name].filter(name => name).join(' ')}</span>;
};

export const getFullName = (firstName, middleName, lastName) => {
  return [firstName, middleName, lastName].filter(name => name)?.join(' ');
};

export const formatFileSize = (sizeInBytes) => {
  const kb = 1024;
  const mb = kb * 1024;
  const gb = mb * 1024;

  if (sizeInBytes >= gb) {
    return (sizeInBytes / gb).toFixed(2) + ' GB';
  } else if (sizeInBytes >= mb) {
    return (sizeInBytes / mb).toFixed(2) + ' MB';
  } else if (sizeInBytes >= kb) {
    return (sizeInBytes / kb).toFixed(2) + ' KB';
  } else {
    return sizeInBytes + ' bytes';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return 'green';
    case 'pending':
      return 'orange';
    case 'failed':
      return 'red';
    default:
      return 'black';
  }
};

export const convertToNumber = (data) => {
  if (data) {
    return parseFloat(data);
  } else {
    return null;
  }
}
